@font-face {
  font-family: 'Baloo Tamma 2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Baloo Tamma 2 Regular'), local('BalooTamma2-Regular'), url(https://fonts.gstatic.com/s/balootamma2/v1/vEFX2_hCAgcR46PaajtrYlBbT0g.ttf) format('truetype');
}
@font-face {
  font-family: 'Baloo Tamma 2';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Baloo Tamma 2 Medium'), local('BalooTamma2-Medium'), url(https://fonts.gstatic.com/s/balootamma2/v1/vEFK2_hCAgcR46PaajtrYlBbd7wf8tI.ttf) format('truetype');
}
@font-face {
  font-family: 'Baloo Tamma 2';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Baloo Tamma 2 SemiBold'), local('BalooTamma2-SemiBold'), url(https://fonts.gstatic.com/s/balootamma2/v1/vEFK2_hCAgcR46PaajtrYlBbd5AY8tI.ttf) format('truetype');
}
@font-face {
  font-family: 'Baloo Tamma 2';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Baloo Tamma 2 Bold'), local('BalooTamma2-Bold'), url(https://fonts.gstatic.com/s/balootamma2/v1/vEFK2_hCAgcR46PaajtrYlBbd_QZ8tI.ttf) format('truetype');
}
@font-face {
  font-family: 'Baloo Tamma 2';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local('Baloo Tamma 2 ExtraBold'), local('BalooTamma2-ExtraBold'), url(https://fonts.gstatic.com/s/balootamma2/v1/vEFK2_hCAgcR46PaajtrYlBbd-ga8tI.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v1/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59FO_F.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v1/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk6jFO_F.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v1/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk79FO_F.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v1/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk7PFO_F.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v1/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4jE-_F.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v1/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4aE-_F.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v1/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59E-_F.ttf) format('truetype');
}
@font-face {
  font-family: 'PT Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('PT Sans Italic'), local('PTSans-Italic'), url(https://fonts.gstatic.com/s/ptsans/v11/jizYRExUiTo99u79D0eEwA.ttf) format('truetype');
}
@font-face {
  font-family: 'PT Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('PT Sans Bold Italic'), local('PTSans-BoldItalic'), url(https://fonts.gstatic.com/s/ptsans/v11/jizdRExUiTo99u79D0e8fOytKA.ttf) format('truetype');
}
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('PT Sans'), local('PTSans-Regular'), url(https://fonts.gstatic.com/s/ptsans/v11/jizaRExUiTo99u79P0U.ttf) format('truetype');
}
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('PT Sans Bold'), local('PTSans-Bold'), url(https://fonts.gstatic.com/s/ptsans/v11/jizfRExUiTo99u79B_mh4Ok.ttf) format('truetype');
}
