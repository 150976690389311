@tailwind base;

@tailwind components;

@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,900;1,400;1,800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Baloo+Tamma+2:wght@400;500;600;700;800&family=Manrope:wght@200;300;400;500;600;700;800&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.text-heading {
    @apply font-display;
}

h1, h2, h3, h4 {
    @apply font-display;
    @apply mb-4 capitalize font-light;
}

h1 {
    @apply text-3xl;
}
h2 {
    @apply text-2xl;
}
h3 {
    @apply text-xl;
}

p, li {
    @apply font-body mb-4;
}

.nav-links a:after {
    content: '';
    width: 0px;
    height: 1px;
    display: block;
    @apply bg-gray-900;
    transition: 300ms;
}

.nav-links a:hover:after {
    width: 100%;
}

.tabs a.active{
    @apply bg-gray-300;
}

.container {
    @apply px-4;
}
@screen md {
    .container {
        @apply px-0;
    }
}

.gradient-bg-1 {
    background: linear-gradient(to bottom, #463F7F, #1F1B43);
    
    .header{ 
        @apply text-white;
        .nav-links a:after {
            @apply bg-gray-100;
        }
    }
}

.max-h-80 {
    max-height: 80vh;
}

.text-shadow{
    color:#79CAFF;
    text-shadow: 0 0 10px #FFFFFF, 40px 30px 4px rgba(206,89,55,0);
}